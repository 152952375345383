import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import colors from "./colors"
import { IconCalendar, IconListDetails, IconLoader2 } from "@tabler/icons-react"
import moment from "moment"
import { TextField } from "@mui/material"
import { config } from "./config"


export default function Job() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [guest, setGuest] = useState('')
  const [name, setName] = useState('')
  const [job, setJob] = useState(null)
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(true)

  const action = searchParams.get('action')
  const id = searchParams.get('id')

  const saveGuest = () => {
    if (name.trim().length > 3) {
      localStorage.setItem('guest', name)
      setGuest(name)
      fetchLogs(name)
    }
  }

  const fetchLogs = async (val) => {
    await axios
      .get(`${config.baseUrl}jobs/guest/logs?guest_name=${val}`, {
        headers: {
          'x-code-header': id,
        },
      })
      .then(resp => {
        setLogs(resp.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchJobDetail = async (val) => {
    axios.get(`${config.baseUrl}jobs/guest`, {
      headers: {
        'x-code-header': id,
      },
    })
    .then(resp => {
      setJob(resp.data);
      if (resp.data.member_jobs.length > 0) {
        fetchLogs(val);
      } else {
        setLoading(false);
      }
    })
    .catch(async (err) => {
      
    })
    .finally(() => {
      if (!guest) {
        //
      }
    });
  }

  useEffect(() => {
    const localGuest = localStorage.getItem('guest')
    setGuest(localGuest)
    fetchJobDetail(localGuest)
  }, [action])

  const gotoDetail = (data) => {
    localStorage.setItem('form', JSON.stringify(data))
    setSearchParams({id: id, action: 'form', status: data.form_status})
  }

  return (
    <div className="bg-[#FBFBFB] flex flex-col w-full min-h-screen">
      <header className={`bg-[${colors.primaryYellow}] p-4 flex flex-row items-center justify-between`}>
        <p className="font-bold">ORDES Form</p>
        <p className="font-bold">{guest}</p>
      </header>
      {
        loading && <div className="w-full h-screen flex flex-row items-center justify-center">
          <IconLoader2 color={colors.primaryBlue} size={32} className="animate-spin" />
        </div>
      }
      {
        job && !loading &&
        <div className="p-4">
          <div className="rounded-lg bg-white p-3 shadow-lg flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <img src={job.partner_logo} alt={job.partner_name} className="w-12 h-12 rounded-xl border" />
              <div className="ml-2 max-w-48">
                <p className={`text-[${colors.baseText}] font-bold`}>{job.title}</p>
                <p className={`text-[${colors.lightGrayText1}] text-xs -mt-1`}>{job.partner_name}</p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="flex flex-row items-center gap-x-1">
                <IconCalendar color={colors.lightGrayText2} size={12} />
                <p className={`text-xs text-[${colors.lightGrayText1}]`}>{moment(job.created_at).format('DD MMM YYYY')}</p>
              </div>
              <p className={`text-[${colors.primaryYellow}] bg-[${colors.blueBackground}] px-2 py-1 mt-1 text-xs rounded-2xl`}>Target: {job.target}</p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-5 gap-x-2">
            <IconListDetails size={24} color={colors.baseText} />
            <p className="font-semibold">Job Form</p>
          </div>
          {
            logs.length === 0 && <div className="flex flex-col w-full items-center flex-1 justify-center mt-32">
              <img src="/form.png" alt="empty form" className="w-1/3" />
              <p className="text-lg mt-4 font-semibold">Form kosong</p>
              <p className={`text-[${colors.lightGrayText2}] text-sm`}>Belum ada form diisi</p>
            </div>
          }
          {
            logs.map((log, logIndex) => {
              const bg = log.form_status === 'review-job'
              ? colors.bgOrange
              : log.form_status === 'draft'
              ? colors.background
              : log.form_status === 'approved'
              ? colors.bgGreen
              : colors.bgRed

              const color = log.form_status === 'review-job'
              ? colors.orange
              : log.form_status === 'draft'
              ? colors.lightGrayText1
              : log.form_status === 'approved'
              ? colors.green
              : colors.red

              const border = log.form_status === 'review-job'
              ? colors.borderOrange
              : log.form_status === 'draft'
              ? colors.lightGrayText2
              : log.form_status === 'approved'
              ? colors.borderGreen
              : colors.red
              return (
                <button onClick={() => { gotoDetail(log) }} key={logIndex} type="button" className="flex flex-row items-center justify-between bg-white p-4 rounded-xl shadow-lg w-full my-2">
                  <div className="flex flex-col items-start">
                    <p className="font-bold">{log.form_input_values.find((e) => e.is_pinned === 1).value ?? ''}</p>
                    <div className="flex flex-row items-center gap-x-1">
                      <IconCalendar color={colors.lightGrayText2} size={12} />
                      <p className={`text-xs text-[${colors.lightGrayText1}]`}>{moment(log.created_at).format('DD MMM YYYY')}</p>
                    </div>
                  </div>
                  <p className={`text-[${color}] bg-[${bg}] border border-[${border}] px-4 py-1 mt-1 text-sm rounded-2xl`}>
                    {log.form_status === 'review-job'
                    ? 'Pending'
                    : log.form_status === 'draft'
                    ? 'Draft'
                    : log.form_status === 'approved'
                    ? 'Disetujui'
                    : 'Ditolak'}
                  </p>
                </button>
              )
            })
          }
        </div>
      }
      <div className="p-4 absolute bottom-0 w-full">
        <button onClick={() => { setSearchParams({action: 'form', id: id}) }} type="button" className={`w-full text-center rounded-lg py-2 text-white bg-[${colors.primaryBlue}]`}>Isi Form</button>
      </div>
      {
        !guest && !loading && <div className="absolute top-0 w-full h-full">
          <div className="w-full h-full bg-black opacity-70 z-10" />
          <div className="bg-white absolute bottom-0 p-4 rounded-t-xl w-full h-48 flex flex-col items-center">
            <p className="font-bold text-lg mb-4">Isi Nomor HP anda untuk memulai isi Form</p>
            <TextField type="number" InputProps={{ style: {borderRadius: 12}}} label="08123..." value={name} onChange={(e) => { setName(e.target.value) }} className="rounded-2xl w-full mt-2" variant="outlined" />
            <button onClick={() => { saveGuest() }} type="button" className={`w-full text-center rounded-lg py-3 mt-4 text-white bg-[${colors.primaryBlue}]`}>Simpan</button>
          </div>
        </div>
      }
    </div>
  )
}
